export default {
  translations: {
    aboutMenu: {
      support: '支持',
      help: '帮助',
      about: '关于',
    },
    screens: {
      receivement: {
        follow_up: '跟进',
        receivement: '接收',
        requestType: '订单类型',
        order: '订单',
        invoice: '发票',
        status: '状态',
        series: '系列',
        product: '产品',
        supplier: '供应商',
        initialDate: '开始日期',
        finalDate: '结束日期',
        details: '详情',
        generateConference: '生成对账单',
        endDivergence: '以差异结束',
        confirmEndDivergence: '确定要以差异结束吗？',
        orderFinisherEnd: '订单以差异结束',
        progress: '进展',
        actions: '操作',
        dateApproved: '批准日期',
        amountSku: 'SKU数量',
        amount: '数量',
        dateReceivement: '接收日期',
        dateEnd: '接收结束日期',
        alertSeach: '请在最多30天的时间范围内输入日期或按订单和发票进行过滤',
        successFilter: '找到结果',
        resultsNotFound: '找不到结果',
        document: '文件',
        receiptDate: '接收日期',
        endDate: '结束日期',
        enterProduct: '输入产品',
        enterBox: '输入箱子',
        enterPallet: '输入托盘',
        dateChecked: '检查日期',
        size: '大小',
        quantity: '数量',
        divergent: '差异',
        dateReceipt: '接收日期',
        successfullyReversed: '成功冲销',
        reversedSuccessfully: '接收成功冲销',
        isReverse: '确定要冲销接收吗？',
        reverse: '撤销',
        youLogin: '您的登录信息',
        password: '密码',
        youPassword: '你的密码',
        autenticateMsg: '哎呀，请检查您的登录名和/或密码！',
        notLeaderMsg: '只有领导才能授权退款',
        revertTitleModal: '领导认证',
        volumes: '卷',
        distributionBox: '配电箱',
        campaign: '动',
      },
      status: {
        canceled: '取消',
        divergent: '差异',
        inProgress: '进行中',
        finished: '已完成',
        completedDivergent: '已完成差异',
        integrated: '已整合OK',
        integratedDivergent: '已整合差异',
        invoiceDivergent: '发票差异',
        pending: '待处理',
        qualityFailed: '质量不合格',
        transit: '交通',
      },
      cardsFilters: {
        results: '结果',
        finished: '已完成',
        completedDivergent: '已完成差异',
        inProgress: '进行中',
        divergent: '差异',
        pending: '待处理',
        totalProgress: '总进展',
        create: '已创建',
        planned: '已计划',
        progress: '进展',
        resupply: '补货',
        completedShipment: '已完成装运',
        finishedDivergence: '已完成差异',
        insufficientStock: '库存不足',
        awaitingIntegration: '等待整合',
        checkedQuantity: '已确认数量',
        pickingQuantity: '數量揀選',
        totalAmount: '总数量',
        startDate: '分离开始时间',
        startTime: '分离开始时间',
        endDate: '装运结束日期',
        endTime: '装运结束时间',
        awaitingBilling: '等待开票',
        awaitingShipment: '等待装运',
        awaitingConference: '等待对账',
        shipmentFinished: '装运已完成',
        awaitingRelease: '等待释放',
        divergentQuantity: '差异数量',
        amountReceived: '收到数量',
        waitingConference: '等待会议',
        waitingShipment: '等待登机',
        orderCancelled: '订单已取消',
        waitingRelease: '等待发布',
        waitingScreening: '等待筛选',
        screeningCompleted: '筛选完成',
        update: '更新',
        delivered: '发表',
      },
      structureAdresses: {
        type: '类型',
        description: '描述',
        isActive: '是否激活',
        noFound: '未找到任何地址',
        adreessesFound: '找到地址',
        structureEditSuccess: '成功编辑结构',
        successDeleteRecordes: '成功删除记录',
        notFoundRecordes: '未找到任何记录',
        wasFound: '找到',
        records: '条记录',
        someError: '请求出现错误',
        structureCreate: '成功创建结构！',
        structure: '结构',
        titleScreen: '创建结构',
        newStrucutureBtn: '新建结构',
        create: '创建',
        createStructureTitle: '创建一个结构',
        edit: '编辑',
        successfullyDeleted: '成功删除！',
        isDeleted: '确定要删除吗？',
        status: '状态',
        actions: '操作',
        delete: '删除',
        statusTrue: '已激活',
        statusFalse: '未激活',
      },
      address: {
        deposit: '存储',
        type: '类型',
        status: '状态',
        adresses: '地址',
        toWalk: '层',
        road: '街道',
        modules: '模块',
        level: '层级',
        position: '位置',
        resulpply: '补货',
        capacity: '容量',
        resu: '补',
        actions: '操作',
        delete: '删除',
        edit: '编辑',
        editStatus: '编辑状态',
        newAddress: '新建地址',
        storage: '存储',
        notFound: '未找到地址记录',
        foundResults: '找到结果',
        error: '错误',
        branch: '分支机构',
        shippingCompany: '物流公司',
        branchsNotName: '没有该名称的分支机构',
        editedSuccessfully: '编辑成功！',
        saveButtonTitle: '保存',
        notLeave: '状态字段不能为空！',
        updateStatus: '状态更新成功！',
        deleteSuccess: '删除成功',
        isDelete: '确定要删除该地址吗',
        deleteAddress: '地址已删除',
        createAddressTitle: '创建地址',
        createAddressSuccess: '创建成功！',
        addressExist: '地址已存在！',
        importFile: '导入文件',
        importSuccess: '文件导入成功！',
        uploadFile: '上传文件',
        choiceFile: '选择要上传的文件。',
      },
      logAddress: {
        product: '产品',
        address: '地址',
        initialDate: '开始日期',
        finalDate: '结束日期',
        box: '箱子',
        pallet: '托盘',
        type: '类型',
        noRecordes: '未找到任何记录！',
        logStorage: '存储日志',
        quantityPalete: '托盘数量',
        quantityBox: '箱子数量',
        quantity: '数量',
        manufacturer: '制造商',
        descriptionProduct: '产品描述',
        dateStorage: '存储日期',
        alertSearch: '请在最多30天的时间范围内输入日期或按产品或地址进行过滤',
      },
      orderFollowUp: {
        title: '订单',
        followUp: '跟进',
        orderType: '订单类型',
        client: '客户',
        period: '周期',
        clientBranch: '客户/分公司',
        receive: '已收到',
        planning: '计划中',
        finished: '已完成',
        endShipment: '结束装运',
        resultsNotFound: '未找到结果。',
        recordsFound: '找到了',
        clientAndBranch: '客户/分公司',
        quantity: '数量',
        received: '已收到',
        check: '检查',
        color: '颜色',
        orderProgress: '订单进度',
        branchsNotName: '没有该名称的分公司',
        printing: '打印中',
        alertSearch: '请在最多30天的时间范围内输入日期或按订单进行过滤',
        separationStart: '离职开始日期',
      },
      reverseOrder: {
        errorTheRequest: '错误：订单',
        thisStatusCreate: '已经处于已创建状态！',
        orderStatus: '订单状态',
        changedSuccessfully: '成功更改！',
        noRecordesFound: '未找到记录。',
        reverse: '冲销',
        wave: '波次',
        editStatusTitle: '编辑状态 - 订单：',
        dateOrder: '订单日期',
        quantityPieces: '数量件数',
        newWave: '新波次',
        alertSearch: '请在最多30天的时间范围内输入日期或按订单或波次进行过滤!',
      },
      createWave: {
        included: '已包含',
        onWave: '在波次中。',
        notIncluded: '未包含',
        branchsNotName: '没有该名称的分公司',
        picking: '拣货',
        createWave: '创建波次',
        taxUnit: 'UF',
        customerBranch: '客户/分公司',
        quantityPieces: '件数',
        newWave: '新波次',
        selectedOrder: '已选订单',
        priority: '优先级',
        splitVolume: '分割货物？',
        alertSearch: '请在最多30天的时间范围内输入日期或按订单进行过滤。',
      },
      followPicking: {
        branchsNotName: '没有该名称的分公司',
        picking: '拣货',
        editUser: '编辑用户',
        changeUser: '更改用户',
        editUserSuccess: '成功编辑用户',
        followPicking: '拣货跟踪',
        separationFollowUp: '分拣跟踪',
        priority: '优先级',
        customerBranch: '客户/分公司',
        quantityPieces: '数量',
        quantityPicking: '拣货数量',
        quantityChecked: '确认数量',
        dateOrder: '订单日期',
        dateFinal: '最终日期',
        document: '文档',
        startDate: '开始日期：',
        finalDate: '结束日期：',
        alertSearch: '请输入最多30天的日期范围或按波次和订单进行过滤。',
        bathModalTitle: '批次',
        selectBath: '选择批次',
      },
      pickingConference: {
        product: '产品：',
        isResult: '成功确认。订单：',
        picking: '拣货',
        conference: '会议',
        conferenceDetails: '会议详情',
        finalize: '完成！',
        conferenceFinished: '会议已完成！',
        boxAlreadyAddressed: '箱子已经被寄送！',
        boxNotExist: '箱子不存在！',
        boxNotBelong: '箱子不属于该订单！',
        invalidBarcode: '无效的条形码',
        validatedSucces: '盒子和 EAN 已成功验证！',
        quantityExceeded: '数量超过限制！',
        insertBox: '请插入箱子以进行拣货！',
        endConference: '您确定要结束会议吗？',
        barCode: '条形码',
        code: '条形码',
        box: '箱子',
        check: '确认',
        conferred: '已确认',
        qty: '数量',
        boxType: '箱子类型',
        bathModalTitle: '批次 ',
        selectBath: '选择批次',
        validity: '有效期',
        checkedProduct: '产品已核对',
        alreadyPackage: '产品已经包装好',
        invalidBarcodeByProduct: '该订单的代码无效',
        notBarcode: '填写条形码',
        grouping: '分组',
        depositCod: '存款代码',
        despositDesc: '存款代码',
        addressCode: '地址码',
        addressDesc: '描述 地址',
        quantityVisit: '数量访问量',
        quantityParts: '数量部分',
        reportVisit: '参观采摘',
        confirmRevertMsg: '确认产品退款',
      },
      transferenceFollowUp: {
        transfer: '调拨',
        origin: '来源',
        destiny: '目的地',
        unit: '单位',
        type: '类型',
        transferDate: '调拨日期',
        alertSearch: '请填写日期, 最多相差30天, 或按单位筛选。',
      },
      expeditionFollowUp: {
        expedition: '发货',
        query: '查询',
        state: '状态',
        dispatchDate: '发货日期',
        customerBranch: '客户/分公司',
        dispatchUser: '发货用户',
        codeOrder: '订单编号',
        codeExpedition: '发货编号',
        validProduct: '有效产品',
        startDate: '发货开始日期',
        endDate: '发货结束日期',
        branchsNotName: '没有符合该名称的分公司',
        alertSearch: '请填写日期, 最多相差30天, 或按订单筛选。',
      },
      boarding: {
        boarding: '装载',
        packingList: '装箱清单',
        customerBranch: '客户/分公司',
        invoiceQuantity: '发票数量',
        volumeQuantity: '体积数量',
        exportButton: '导出装箱清单 (PDF) ',
        requestCode: '订单编号',
        orderType: '订单类型',
        packingDetails: '装箱清单详情',
        recordFound: '找到记录',
        agent: '代理人',
        enterAgent: '输入代理',
        agentError: '错误。与您的代理联系',
        alertSearch: '请填写日期, 最多相差30天, 或按装箱清单筛选。',
      },
      manualOrder: {
        manualOrder: '手动订单',
        fileUpload: '文件上传',
        register: '注册',
        requireFilds: '请填写必填字段',
        receipt: '收据:',
        successfullyCreated: '创建成功！',
        order: '订单:',
        created: '已创建！',
        operation: '操作',
        chooseOperation: '选择操作',
        prohibited: '入库',
        exit: '出库',
        chooseType: '选择类型',
        enterInvoice: '输入发票',
        series: '系列',
        date: '日期',
        branchCustomer: '客户ERP代码/分公司',
        carrierCode: '承运商代码',
        supplier: '供应商',
        chooseSupplier: '选择供应商',
        items: '物品',
        deletedItems: '已删除的物品',
        deletedItem: '已删除物品',
        withSuccess: '成功',
        ordem: '顺序',
        delete: '删除',
        validData: '请输入有效数据',
        addedSuccessfully: '添加成功！',
        nameAndCode: '没有找到该名称/代码的产品',
        chooseProduct: '选择一个产品',
        enterAmount: '输入数量',
        toAdd: '添加',
        chooseUpload: '选择要上传的文件。',
        liftFile: '上传文件',
        chooseRegistration: '选择注册方式',
      },
      parameters: {
        parameters: '参数',
        administration: '管理',
        successfullyEdited: '成功编辑参数！',
        enterParemeter: '请输入参数',
        parametersDescription: '请输入参数描述',
        value: '值',
      },
      registerProduct: {
        searchProduct: '搜索产品！',
        thereProducts: '没有产品',
        productsFound: '找到产品',
        register: '注册',
        productCode: '产品代码',
        marked: '已标记',
        unchecked: '未标记',
        productEdited: '产品编辑成功',
        active: '激活',
        level: '级别',
        height: '高度',
        width: '宽度',
        length: '长度',
        weight: '重量',
        component: '组件',
        brandCode: '品牌代码',
        receipt: '收货',
        receiptInDays: '收货（天）',
        exit: '出库',
        parameters: '参数',
        marketingStructure: '营销结构',
        departureInDays: '出库（天）',
        batchControl: '批次控制',
        controlsValidity: '有效期控制',
        controlsReceipt: '收货批次控制',
        receiptValidity: '收货有效期控制',
        constrolStorage: '存储批次控制',
        storageValidity: '存储有效期控制',
        constrolSeparation: '分拣批次控制',
        separationValidity: '分拣有效期控制',
        checkControl: '核对批次控制',
        validityCheck: '核对有效期控制',
        notFieldsEmpty: '不要留空字段',
        colorOrDescription: '颜色或描述',
        colorDesc: '颜色/描述',
        delete: '删除',
      },
      produtivityConference: {
        produtivity: '生产力',
        conference: '会议',
        name: '姓名',
        summaryTable: '综合表',
        analyticalTable: '分析表',
        totalHours: '总小时数',
        ordersTime: '每小时订单数',
        amountOrder: '订单数量',
        amountPending: '数量待办的',
        amountPackaged: '数量包装好的',
        addressesTime: '每小时地址数',
        amountAddresses: '地址数量',
        productTime: '每小时产品数',
        amountProduct: '产品数量',
        researchDate: '调研日期',
        fisrtMessage: '在分析表中找到了结果，并且在综合表中找到了',
        lastMessage: '结果',
        alertSearch: '请输入最多30天的日期或按姓名过滤。',
        avgHours: '平均起始小时数',
        endAvgHours: '平均结束小时数',
        totalHourOrders: '总订单小时数',
        quantityHours: '每小时订单数量',
        quantityAddress: '每小时地址数量',
        totalAddress: '总地址数量',
        productHours: '总产品小时数',
        quantityHoursTotal: '总产品数量',
        printMsg: '产品已 100% 检查完毕，您要打印吗？',
        store: '店铺',
      },
      produtivityStorage: {
        notFieldEmpty: '请勿留空此字段',
        choiceDate: '选择起始日期和结束日期！',
      },
      reportsStock: {
        boarding: '装船',
        brand: '品牌',
        address: '地址',
        descriptionProduct: '产品描述',
        codeRequest: '订单编码',
        reserved: '已预留',
        available: '可用',
        inbound: '入境',
        outBound: '出境',
        destinationStore: '目的地商店',
        reservation: '预订',
      },
      invoiceDivergent: {
        reports: '报告',
        orderDivergent: '订单差异',
        series: '系列',
        date: '日期',
        invoiceQuantity: '发票数量',
        orderQuantity: '订单数量',
        alertSearch: '请输入最多30天的日期或按订单过滤。',
      },
      ressupply: {
        ressupply: '补给',
        origin: '起始地',
        destiny: '目的地',
        priority: '优先级',
        reports: '报告',
        supply: '供应',
        creatSupply: '创建供应',
        request: '要求',
        createSubRomaning: '创建子罗马字',
        SubRomaning: '罗马尼奥号',
        qtyFirstFase: '数量第一阶段',
        homeCollection: '家居系列一期',
        endCollection: '结束第一阶段收集',
        qtyLastFase: '数量第二期',
        lastCollection: '家居系列二期',
        endLastCollection: '第二阶段收集结束',
      },
      insufficient: {
        insufficient: '不足',
        reports: '报告',
        priority: '优先级',
      },
      separation: {
        reports: '报告',
        separation: '分拣',
        quantityPickink: '拣货数量',
        quantityOrder: '订单数量',
        series: '系列',
        nameClient: '客户名称',
        codeClient: '客户代码',
        codShippingCompany: '物流公司代码',
        descriptionAddress: '地址描述',
        billingIssue: '开票问题',
        billingDate: '开票日期',
        homeSeparation: '开始分拣',
        endSeparation: '结束分拣',
      },
      conference: {
        reports: '报告',
        conference: '会议',
        quantityConference: '会议数量',
        quantityOrder: '订单数量',
        series: '系列',
        nameClient: '客户名称',
        integrationDate: '集成日期',
        homeConference: '开始会议',
        endConference: '结束会议',
        finalize: '完成',
        station: '站点',
        selectStation: '选择站点',
        errorMessage: '哎呀！请检查是否填写了所有的订单和站点',
      },
      reportReceipt: {
        receipt: '收据',
        batch: '批',
        enterBach: '输入批次',
        reports: '报告',
        series: '系列',
        enterSeries: '进入系列',
        integrationDate: '集成日期',
        alertSearch: '请输入最多30天的日期或按订单或发票过滤。',
      },
      dashboard: {
        awaitingBilling: '等待开票',
        awaitingIntegration: '等待集成',
        conference: '会议',
        ddressesUsed: '使用的地址',
        stock: '库存',
        expedition: '发货',
        insufficient: '不足',
        requests: '订单',
        pickingProgress: '拣货进度',
        divergentPicking: '拣货差异',
        plannedPicking: '计划拣货',
        resupply: '补给',
      },
      reportStock: {
        stored: '存储',
        shipped: '装运',
        invoiced: '已开票',
      },
      pack: {
        packCreatSuccess: '包创建成功',
        pack: '创建包',
        validatedSucces: 'EAN 已成功验证！',
        errorValidated: '错误。颜色和产品与已列出的不同！',
      },
      streetOccupation: {
        totalOccupation: '总占用量',
        occupationStreet: '街头职业',
        occupation: '忙碌的',
        empty: '空的',
      },
      commom: {
        storage: '存储',
        picking: '拣货',
        detail: '详情',
        details: '详细信息',
        actions: '操作',
        user: '用户',
        order: '订单',
        status: '状态',
        initialDate: '开始日期',
        startTime: '开始时间',
        finalDate: '结束日期',
        endTime: '结束时间',
        totalTime: '总时间',
        shippingCompany: '运输公司',
        branch: '分公司',
        progress: '进度',
        records: '记录',
        product: '产品',
        descriptionProduct: '产品描述',
        box: '箱子',
        pallet: '托盘',
        grid: '网格',
        size: '尺寸',
        invoice: '发票',
        recordsFound: '找到',
        client: '客户',
        edit: '编辑',
        saveButtonTitle: '保存',
        orderType: '订单类型',
        resultsNotFound: '未找到结果。',
        period: '周期',
        orderDate: '订单日期',
        yes: '是',
        no: '否',
        create: '创建',
        followUp: '跟进',
        wave: '波次',
        color: '颜色',
        quantity: '数量',
        toClean: '清除',
        search: '搜索',
        finalize: '完成',
        code: '代码',
        successFilter: '找到结果',
        description: '描述',
        error: '错误',
        branchsNotName: '没有找到匹配的分公司名称！',
        toAdd: '添加',
        deposit: '存款',
        level: '级别',
        stock: '库存',
        reports: '报告',
        dashboard: '仪表板',
        autenticate: '验证',
        seller: '卖方',
        formPayment: '付款方式',
        total: '全部的',
        barCode: '条码',
        enterBarCode: '输入条形码',
        print: '打印机',
        printMsg: '打印已发送。请核实！',
        cage: '笼',
        expirationData: '截止日期',
        quantityDivergente: '数量发散型',
        storageRequest: '存储请求',
        quantityPack: '数量盒',
        totalParts: '零件总数',
        alertAllFields: '填写所有字段',
        class: '班级',
        model: '模型',
        selectStreet: '选择街道',
        conect: '连接',
        observation: '观察',
        heatMap: '热图',
        distribution: '分配',
      },
      integration: {
        title: '监控',
        subTitle: '进程',
        process: '进程',
        last_sync_at: '上次同步时间',
        last_sync_status: '上次同步状态',
        next_sync_at: '下次同步时间',
        running: '运行中',
        stopped: '已停止',
        error: '错误',
      },
      followAge: {
        daysInStock: '庫存天數',
        tenDays: '10天',
        twentyDays: '20天',
        thirtyDays: '30天',
      },
      followWave: {
        followWave: '波浪追踪',
        amountOrder: '订单数量',
        amountProduct: '产品数量',
        waveDate: '日期波',
      },
      initialIntegration: {
        module: '模块',
        autorization: '授权',
        inventoryDate: '库存日期',
        createdDateInventory: '创建日期',
        selectFile: '选择文件',
        newInventoryTitle: '新库存',
        inventory: '存货',
        inventoryCode: '库存代码',
        home: '家',
        selectClass: '选择班级',
        detailInventory: '库存详情',
        firstCollect: '第一个收藏',
        lastCollectt: '最后收藏',
        message: '信息',
        errorMessage: '在以下地址创建意图时出错',
        notFoundMessage: '结果未找到。检查所选过滤器或库存',
        selectInventory: '选择库存',
      },
      scoreInventory: {
        score: '分数',
        bar: '酒吧',
        brand: '品牌',
        reference: '参考',
        notScoreMsg: '该库存尚未开始盘点',
        totalAddresses: '总地址',
        firstCount: '第一次计数',
        secondCount: '第二次计数',
        thistCount: '第三次计数',
        consolidated: '联合',
        countedAddresses: '统计地址',
        itemsCounted: '计数项目',
        skusCounted: 'SKU 计数',
        totalConsolidated: '总计合并',
        notFoundAddress: '库存不包含地址！',
      },
      finallyInventory: {
        finallyInventory: '确定库存',
        reopenInventory: '重新打开库存',
        updateStock: '更新 WMS 库存',
        updateMessage: 'WMS库存更新成功!',
        warnningMessage: '您确定要使用合并库存更新 WMS 库存吗',
      },
      divergentInventory: {
        difference: '不同之处',
        statusCollect: '收款状态',
        statusAddress: '状态地址',
        divergentAddress: '地址分歧',
        piecesCounted: '计件数',
        productDivergent: '发散产品',
      },
      statusAddress: {
        addressCode: '地址码',
        addressResetMsg: '地址重置成功',
        confirmResetMsg: '确认清除地址',
      },
      printOutBox: {
        generateBox: '生成',
        header: '标头',
        selectAgent: '选择代理',
        conectedSuccesfully: '代理连接成功',
        alertMsg: '描述或产品填写过滤器',
        purchaseOrder: '采购订单',
      },
      parametrization: {
        parameterize: '参数化',
        collected: '集',
        resetProductMsg: '产品重置成功',
        questionReset: '您想重置产品吗',
        theAddress: '在地址',
      },
      printWave: {
        waveCode: '波码',
        planningDate: '计划日期',
      },
      printDanfe: {
        keyInvoice: '钥匙',
        corporateName: '公司名称',
        stateRegistration: '国家注册',
        totalValue: '数量',
        issuanceDate: '发行日期',
      },
      printMail: {
        mail: '邮件',
        name: '收件人姓名',
        observation: '笔记。接受者',
        description: '描述。接受者',
        number: '发件人号码',
        neighborhood: '发件人邻居',
        zipCode: '邮政编码。发件人',
        city: '发件人城市',
        shipping: '描述。航运公司',
      },
      indicators: {
        indicator: '指标',
        alertSearch: '按最多 31 天进行过滤并选择类型',
      },
      docking: {
        screening: '筛选',
        pieces: '部分',
        driver: '司机',
        maturyti: '到期',
        newRoute: '新航线',
        transport: '运输',
        routes: '路线',
        capacity: '容量',
        createRoute: '创建路线',
        editRoute: '编辑路线',
      },
      quality: {
        quality: '质量',
        parameters: '参数',
        editParameters: '编辑参数',
        salveParameters: '保存参数',
        newParameters: '新参数',
        descriptionsTitle: '参数说明',
        defaultTitle: '质量控制的采样频率',
        fromBox: '来自 X 盒子',
        upTheBox: '最多 X 盒',
        quantityQuality: '数量质量',
        deleteParameters: '删除参数',
      },
    },
    components: {
      search: {
        title: '搜索',
        clear: '清除',
        advancedFilters: '高级过滤器',
        closeAdvancedFilters: '收起过滤器',
        upload: '上传文件',
        loading: '加载中',
        filter: '过滤器',
      },
      table: {
        export: '导出',
        entries: '条目',
        to: '至',
        noResults: '没有找到记录',
      },
      user: {
        greetings: '你好',
      },
      pageMode: {
        close: '关闭',
        back: '返回',
        printOut: '打印',
      },
      confirmationModal: {
        close: '关闭',
        confirm: '确认',
        cancel: '取消',
      },
      userPopper: {
        profile: '个人资料',
        config: '设置',
      },
    },
  },
};
